/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://unsplash.com/photos/zwd435-ewb4"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2023/01/apple-unveils-m2-pro-and-m2-max-next-generation-chips-for-next-level-workflows/"
  }, "Apple, 차세대 워크플로를 위한 차세대 칩 M2 Pro 및 M2 Max 공개")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2023/01/apple-unveils-macbook-pro-featuring-m2-pro-and-m2-max/"
  }, "Apple, 판도를 바꾸는 성능과 Mac 사상 최장의 배터리 사용 시간을 제공하는 M2 Pro 및 M2 Max를 탑재한 MacBook Pro 공개")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/homepod-2nd-generation/"
  }, "Apple HomdPod 2nd Gen")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%8B%9C%EA%B7%B8%EB%AA%A8%EC%9D%B4%EB%93%9C_%ED%95%A8%EC%88%98"
  }, "시그모이드 함수 - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B8%B0%EA%B3%84_%ED%95%99%EC%8A%B5"
  }, "머신러닝(기계학습) - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%94%A5_%EB%9F%AC%EB%8B%9D"
  }, "딥 러닝 - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/ChatGPT"
  }, "ChatGPT - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.deepmind.com/research/highlighted-research/alphago"
  }, "AlphaGo")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.deepmind.com/blog/alphastar-mastering-the-real-time-strategy-game-starcraft-ii"
  }, "AlphaStar: Mastering the real-time strategy game StarCraft II")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://openai.com/blog/openai-five-defeats-dota-2-world-champions/#arena"
  }, "OpenAI Five Defeats Dota 2 World Champions")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.deepmind.com/blog/muzero-mastering-go-chess-shogi-and-atari-without-rules"
  }, "MuZero: Mastering Go, chess, shogi and Atari without rules")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://openai.com/product/dall-e-2"
  }, "OpenAI DALL·E 2")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/features/copilot"
  }, "GitHub Copilot")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
